import useAmplitude from 'lib/amplitude-tracking';
import {
    passkeyCreationStarted,
    passkeyCreated, 
    currentUserLogged
} from 'modules/tracking/tracking-constants';

const useAmplitudePageView = () => {
    const { track } = useAmplitude();

    return (eventName, payload = {}) => {
        let amplitudeEventName;

        switch(eventName) {
            case passkeyCreationStarted:
                amplitudeEventName = 'Passkey - initiated';
                break;
            
            case passkeyCreated:
                amplitudeEventName = 'Passkey - created';
                break;
            
            case currentUserLogged:
                amplitudeEventName = 'User - first landing';
                break;

            default:
                amplitudeEventName = eventName;
        }

        return track(amplitudeEventName, payload);
    };
};

export default useAmplitudePageView;
